<template>
  <div class="row mx-0 justify-content-center text-center p-3 position-relative">
    <div class="col-12 green-text-dark font12 bold mb-1">
      PAYMENT METHOD
    </div>
    <div class="col-12 green-text-dark">
      <span>Card (Online Setup)</span>
    </div>
    <div class="col-12 font12">
      Starting {{dateDisplay}}
    </div>
  </div>
</template>
<script>
import { DateTime } from 'luxon'

export default {
  name: 'Review Donation Payment Summary',
  data () {
    return {
      isHover: false
    }
  },
  computed: {
    dateDisplay () {
      return DateTime.now().toFormat('dd LLLL yyyy')
    }
  }
}
</script>
